import logoBestia from "./assets/img/la-bestia/01.png";
import bestia1 from "./assets/img/la-bestia/01.jpg";
import bestia2 from "./assets/img/la-bestia/02.jpg";
import bestia3 from "./assets/img/la-bestia/03.jpg";
import bestia4 from "./assets/img/la-bestia/04.jpg";
import bestia5 from "./assets/img/la-bestia/05.jpg";
import bestia6 from "./assets/img/la-bestia/06.jpg";
import bestia7 from "./assets/img/la-bestia/07.jpg";
import bestia8 from "./assets/img/la-bestia/08.jpg";
import bestia9 from "./assets/img/la-bestia/09.jpg";
import bestia10 from "./assets/img/la-bestia/10.jpg";
import lorenzo1 from "./assets/img/lorenzo/01.jpg";
import logoLorenzo from "./assets/img/lorenzo/01.png";
import lorenzo2 from "./assets/img/lorenzo/02.jpg";
import lorenzo3 from "./assets/img/lorenzo/03.jpg";
import lorenzo4 from "./assets/img/lorenzo/04.jpg";
import lorenzo5 from "./assets/img/lorenzo/05.jpg";
import lorenzo6 from "./assets/img/lorenzo/06.jpg";
import lorenzo7 from "./assets/img/lorenzo/07.jpg";

const SLIDER_BESTIA = [
  {
    id: 1,
    img: bestia1,
    alt: "bestia1",
  },
  {
    id: 2,
    img: logoBestia,
    alt: "logoBestia",
  },
  {
    id: 3,
    img: bestia2,
    alt: "bestia2",
  },
  {
    id: 4,
    img: bestia3,
    alt: "bestia2",
  },
  {
    id: 5,
    img: bestia4,
    alt: "bestia2",
  },
  {
    id: 6,
    img: bestia5,
    alt: "bestia2",
  },
  {
    id: 7,
    img: bestia6,
    alt: "bestia2",
  },
  {
    id: 8,
    img: bestia7,
    alt: "bestia2",
  },
  {
    id: 9,
    img: bestia8,
    alt: "bestia2",
  },
  {
    id: 10,
    img: bestia9,
    alt: "bestia2",
  },
  {
    id: 11,
    img: bestia10,
    alt: "bestia2",
  },
];

const SLIDER_LORENZO = [
  {
    id: 1,
    img: lorenzo1,
    alt: "bestia1",
  },
  {
    id: 2,
    img: logoLorenzo,
    alt: "logoBestia",
  },
  {
    id: 3,
    img: lorenzo2,
    alt: "bestia2",
  },
  {
    id: 4,
    img: lorenzo3,
    alt: "bestia2",
  },
  {
    id: 5,
    img: lorenzo4,
    alt: "bestia2",
  },
  {
    id: 6,
    img: lorenzo5,
    alt: "bestia2",
  },
  {
    id: 7,
    img: lorenzo6,
    alt: "bestia2",
  },
  {
    id: 8,
    img: lorenzo7,
    alt: "bestia2",
  },
];

const FESTIVALES = [
  {
    id: 1,
    festival: "Festival Payasadas",
    lugar: "Rosario-Argentina",
  },
  {
    id: 2,
    festival: "Festival Internacional “Festiclown de Primaveras“",
    lugar: "Santa Fe-Argentina",
  },
  {
    id: 3,
    festival: "Festival Invernal de “Circo en la carpa La Moringa“",
    lugar: "Entre Ríos-Argentina",
  },
  {
    id: 4,
    festival: "Festival “Enpayasados”",
    lugar: "Zaragosa-España",
  },
  {
    id: 5,
    festival: "Festival de “Teatro del Borde”",
    lugar: "Buenos Aires–Argentina",
  },
  {
    id: 6,
    festival: "Festival de Teatro de Rafaela",
    lugar: "Rafaela–Argentina",
  },
  {
    id: 7,
    festival: "Festival Payasadas",
    lugar: "Rosario-Argentina",
  },
  {
    id: 8,
    festival: "Festival Koblakari",
    lugar: "País Vasco-España",
  },
  {
    id: 9,
    festival: "Festival Artekale",
    lugar: "País Vasco – España",
  },
  {
    id: 10,
    festival:
      "Festival de teatro Esther Estelar. Participa en dos de sus ediciones",
    lugar: "Pueblo Esther-Argentina",
  },
  {
    id: 11,
    festival:
      "Festival de magia de Rosario. Participa en tres de sus ediciones.",
    lugar: "Rosario-Argentina",
  },
  {
    id: 12,
    festival: "Primer festival de clown de Buenos Aires",
    lugar: "Bs As-Argentina",
  },
  {
    id: 13,
    festival: "Festival de las artes en San Justo",
    lugar: "San Justo-Argentina",
  },
];

const CONVOCATORIAS = [
  {
    id: 1,
    festival:
      "Actuación en el ciclo “Un veranito fresquito” organizado por la Secretaría de Cultura de la ciudad de Rosario (Argentina). Queda seleccionado entre ochenta compañías de la ciudad.",
  },
  {
    id: 2,
    festival:
      "Actuación en el ciclo “Ja ja ja” Organizado por la secretaría de cultura de la ciudad de Rosario (Rosario-Argentina).",
  },
  {
    id: 3,
    festival: "Actuación en la “Usina del Humor” (Entre Ríos –Argentina)",
  },
  {
    id: 4,
    festival:
      "Presentador en la tercera edición de la convención de circo de Rosario (Rosario-Argentina).",
  },
  {
    id: 5,
    festival:
      "Fue convocado por la Secretaría de Cultura de la ciudad de Rafaela para realizar la producción de la carpa de circo en el Festival Internacional de teatro de dicha ciudad en el año 2019 (Rafaela- Argentina).",
  },
  {
    id: 6,
    festival:
      "Queda seleccionado en “Guiso de Artistas”, reconocimiento que realiza la Secretaría de Cultura de la Provincia de Santa Fe a la tarea de artistas independientes de dicha provincia.",
  },
  {
    id: 7,
    festival:
      "Queda seleccionado para formar parte de la Compañía de la Comedia Municipal de la ciudad de Rosario realizando el papel protagónico dentro de la obra Doña Disparate y Bambuco de Maria Elena Walsh (Argentina).",
  },
];

export { SLIDER_BESTIA, SLIDER_LORENZO, FESTIVALES, CONVOCATORIAS };
